<template>
  <div v-if="procurementsTracking && resultsBy == 'item_type'">
    <span>Focus</span>
    <div>
      <div class="select-wrapper">
        <select @change="urlModifier($event.target.value)" v-model="selectedItemType">
          <option v-for="itemType in availableItemTypes" :key="itemType" :value="itemType">
            {{ i18n.t('lookups.meter_types.' + itemType) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    defaultParams: Object,
    procurementsTracking: {
      type: Boolean,
      default: false,
    },
    availableItemTypes: Array,
  },
  data: function () {
    return {
      i18n: this.$parent.i18n,
      resultsBy: null,
      selectedItemType: null,
    };
  },
  methods: {
    urlModifier: function (value) {
      let url = new URL(window.location.href);
      url.searchParams.set('item_type', value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.resultsBy = this.defaultParams['results_by'] || url.searchParams.get('results_by');
    this.selectedItemType = url.searchParams.get('item_type') || 'all';
  },
};
</script>
