import { Controller } from '@hotwired/stimulus';
import { numberWithDelimiter } from '../helpers/numberWithDelimiter';

export default class extends Controller {
  static targets = ['input', 'output', 'percentageValue'];
  static values = {
    total: Number,
    delimiter: String,
  };

  connect() {
    this.updateInput();
  }

  updateInput() {
    const min = this.inputTarget.min;
    const max = this.inputTarget.max;
    const val = this.inputTarget.value;

    this.inputTarget.style.backgroundSize = ((val - min) * 100) / (max - min) + '% 100%';

    this.updateOutput();
  }

  updateOutput() {
    if (this.hasOutputTarget) {
      if (this.totalValue) {
        this.outputTarget.innerHTML = 100 - this.inputTarget.value;
      } else {
        this.outputTarget.innerHTML = this.inputTarget.value;
      }
    }

    if (this.totalValue && this.hasPercentageValueTarget) {
      this.percentageValueTarget.innerHTML = numberWithDelimiter(
        this.delimiterValue,
        (this.inputTarget.value * this.totalValue) / 100
      );
    }
  }
}
