var render = function render(){var _vm=this,_c=_vm._self._c;return (
    ((_vm.fuelGroup == 'footprint' && _vm.offsetting == 'false' && _vm.byTargets == 'false') ||
      _vm.fuelGroup == 'procurement' ||
      _vm.byScope == 'true') &&
    !_vm.scope2Comparisson
  )?_c('div',{staticClass:"button-filters report"},[_c('span',[_vm._v("Report")]),_vm._v(" "),_c('div',{class:[
      'icon filter-icon circled tooltip market',
      { 'icon-active': _vm.reportType == 'market' },
    ],on:{"click":function($event){return _vm.urlModifier('report_type', 'market')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/carbon_market_based.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Market-based emissions")])],1),_vm._v(" "),_c('div',{class:[
      'icon filter-icon circled tooltip location',
      { 'icon-active': _vm.reportType == 'location' },
    ],on:{"click":function($event){return _vm.urlModifier('report_type', 'location')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/carbon_location_based.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Location-based emissions")])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }