<template>
  <div class="select-wrapper">
    <span id="ruler" class="ruler">{{ selectedMeterName }}</span>
    <select @change="urlModifier('meter', $event)" :style="selectStyle">
      <option>{{ prompt }}</option>
      <optgroup v-if="visibleMeters.length" label="Visible Meters">
        <option
          v-for="meter in visibleMeters"
          :key="meter.slug"
          :value="meter.slug"
          :selected="meter.slug == selectedMeter"
          >{{ meter.name }}
        </option>
      </optgroup>
      <optgroup v-if="hiddenMeters.length" label="Hidden Meters">
        <option
          v-for="meter in hiddenMeters"
          :key="meter.slug"
          :value="meter.slug"
          :selected="meter.slug == selectedMeter"
          >{{ meter.name }}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    prompt: String,
    visibleMeters: Array,
    hiddenMeters: Array,
  },

  data: function () {
    return {
      selectStyle: {
        maxWidth: 'none',
        width: '',
      },
      selectedMeter: '',
      selectedMeterName: '',
    };
  },

  methods: {
    urlModifier: (parameter, value) => {
      let url = new URL(window.location.href);

      url.searchParams.delete(parameter);
      value = value.target.value;
      url.searchParams.set(parameter, value);

      url.searchParams.sort();
      window.location.href = url;
    },

    meterOptionLength: () => {
      let ruler = document.getElementById('ruler');
      return ruler.offsetWidth;
    },
  },

  mounted: function () {
    let url = new URL(window.location.href);
    this.selectedMeter = url.searchParams.get('meter');
    this.selectedMeterName = this.visibleMeters.find((meter) => meter.slug == this.selectedMeter);
    this.selectedMeterName =
      this.selectedMeterName || this.hiddenMeters.find((meter) => meter.slug == this.selectedMeter);
    this.selectedMeterName = this.selectedMeterName.name;
    setTimeout(() => {
      this.selectStyle.width = `${this.meterOptionLength() + 100}px`;
    });
  },
};
</script>
