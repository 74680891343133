<template>
  <div class="chart-container">
    <form data-controller="form" data-form-target="form" data-form-force-visit-value="true">
      <div class="filters">
        <div :class="{ 'align-self-baseline': defaultParams.fuel_group == 'environment' }">
          <fuels-filter :default-params="defaultParams" :fuels="fuels"> </fuels-filter>

          <tracking-type-filter
            :default-params="defaultParams"
            :report="report"
            :carbon-footprints="carbonFootprints"
            :by-scope="byScope"
            :show-other-options="showOtherOptions"
            :offsetting="offsetting"
            :offsetting-report="offsettingReport"
            :scope="scope"
            :last-updated-reading-date="lastUpdatedReadingDate"
            :last-refresh-date="lastRefreshDate"
            :updating="updating"
          >
          </tracking-type-filter>

          <results-by-filter
            :default-params="defaultParams"
            :procurements-tracking="procurementsTracking"
            :procurements-league="procurementsLeague"
          >
          </results-by-filter>

          <top-emissions-filter
            :default-params="defaultParams"
            :procurements-league="procurementsLeague"
          >
          </top-emissions-filter>

          <chart-type-filter
            :default-params="defaultParams"
            :carbon-footprints="carbonFootprints"
            :procurements-tracking="procurementsTracking"
            :chart="false"
            :chart-type-selector="false"
          >
          </chart-type-filter>

          <report-type-filter :default-params="defaultParams" :by-scope="byScope">
          </report-type-filter>

          <group-by-filter :default-params="defaultParams" :grouping="grouping"> </group-by-filter>

          <footprint-groups-filter :carbon-footprints="carbonFootprints"> </footprint-groups-filter>

          <meter-tracking-filter
            :basic-tracking="basicTracking"
            :building="building"
            :per-meter="perMeter"
          >
          </meter-tracking-filter>

          <action-summary-type-filter
            :default-params="defaultParams"
            :action-summary="actionSummary"
          >
          </action-summary-type-filter>

          <league-filter :submeters="submeters"> </league-filter>
        </div>

        <div>
          <chart-type-filter
            :default-params="defaultParams"
            :chart="chart"
            :chart-type-selector="chartTypeSelector"
          >
          </chart-type-filter>

          <period-filter :default-params="defaultParams" :periodical="periodical"> </period-filter>

          <div v-if="timeOfUseView" class="button-filters view-type">
            <span>{{ i18n.t('general.view_type') }}</span>
            <div
              :class="['icon filter-icon circled building', { 'icon-active': !interval }]"
              @click="removeInterval()"
            >
              <inline-svg :src="require('images/icons/building.svg')"></inline-svg>
            </div>
            <div
              :class="[
                'icon filter-icon circled monthly',
                { 'icon-active': interval == 'monthly' },
              ]"
              @click="urlModifier('interval', 'monthly')"
            >
              <inline-svg :src="require('images/icons/monthly.svg')"></inline-svg>
            </div>
            <div
              :class="['icon filter-icon circled weekly', { 'icon-active': interval == 'weekly' }]"
              @click="urlModifier('interval', 'weekly')"
            >
              <inline-svg :src="require('images/icons/weekly.svg')"></inline-svg>
            </div>
          </div>

          <period-filter :default-params="defaultParams" :intervals="intervals"> </period-filter>

          <compare-filter :default-params="defaultParams" :trends="trends"> </compare-filter>

          <div v-if="basicTracking" class="button-filters interval">
            <span>{{ i18n.t('general.interval') }}</span>
            <div
              v-if="defaultParams.fuel_group !== 'environment'"
              :class="['icon filter-icon circled yearly', { 'icon-active': interval == 'yearly' }]"
              @click="urlModifier('interval', 'yearly')"
            >
              <inline-svg :src="require('images/icons/yearly.svg')"></inline-svg>
            </div>
            <div
              v-if="defaultParams.fuel_group !== 'environment'"
              :class="[
                'icon filter-icon circled quarterly',
                { 'icon-active': interval == 'quarterly' },
              ]"
              @click="urlModifier('interval', 'quarterly')"
            >
              <inline-svg :src="require('images/icons/quarterly.svg')"></inline-svg>
            </div>
            <div
              :class="[
                'icon filter-icon circled monthly',
                { 'icon-active': interval == 'monthly' },
              ]"
              @click="urlModifier('interval', 'monthly')"
            >
              <inline-svg :src="require('images/icons/monthly.svg')"></inline-svg>
            </div>
            <div
              :class="['icon filter-icon circled weekly', { 'icon-active': interval == 'weekly' }]"
              @click="urlModifier('interval', 'weekly')"
            >
              <inline-svg :src="require('images/icons/weekly.svg')"></inline-svg>
            </div>
            <div
              :class="['icon filter-icon circled daily', { 'icon-active': interval == 'daily' }]"
              @click="urlModifier('interval', 'daily')"
            >
              <inline-svg :src="require('images/icons/daily.svg')"></inline-svg>
            </div>
            <template v-if="building">
              <div
                :class="[
                  'icon filter-icon circled half_hourly',
                  { 'icon-active': interval == 'half_hourly' },
                ]"
                @click="urlModifier('interval', 'half_hourly')"
              >
                <inline-svg :src="require('images/icons/half_hourly.svg')"></inline-svg>
              </div>
            </template>
          </div>

          <div v-if="league" class="button-filters interval">
            <span>Interval</span>
            <div
              :class="['icon filter-icon circled yearly', { 'icon-active': interval == 'yearly' }]"
              @click="urlModifier('interval', 'yearly')"
            >
              <inline-svg :src="require('images/icons/yearly.svg')"></inline-svg>
            </div>
            <div
              :class="[
                'icon filter-icon circled quarterly',
                { 'icon-active': interval == 'quarterly' },
              ]"
              @click="urlModifier('interval', 'quarterly')"
            >
              <inline-svg :src="require('images/icons/quarterly.svg')"></inline-svg>
            </div>
            <div
              :class="[
                'icon filter-icon circled monthly',
                { 'icon-active': interval == 'monthly' },
              ]"
              @click="urlModifier('interval', 'monthly')"
            >
              <inline-svg :src="require('images/icons/monthly.svg')"></inline-svg>
            </div>
            <div
              :class="['icon filter-icon circled weekly', { 'icon-active': interval == 'weekly' }]"
              @click="urlModifier('interval', 'weekly')"
            >
              <inline-svg :src="require('images/icons/weekly.svg')"></inline-svg>
            </div>
            <div
              :class="['icon filter-icon circled daily', { 'icon-active': interval == 'daily' }]"
              @click="urlModifier('interval', 'daily')"
            >
              <inline-svg :src="require('images/icons/daily.svg')"></inline-svg>
            </div>
          </div>

          <peak-filter :default-params="defaultParams" :time-of-use="timeOfUse"> </peak-filter>

          <template v-if="benchmark">
            <benchmark-type-filter
              :default-params="defaultParams"
              :available-benchmark-types="availableBenchmarkTypes"
            >
            </benchmark-type-filter>

            <div v-if="!building" class="button-filters group-by">
              <span>Group by</span>
              <div
                :class="[
                  'icon filter-icon circled building',
                  { 'icon-active': groupBy == 'building' },
                ]"
                @click="urlModifier('group_by', 'building')"
              >
                <inline-svg :src="require('images/icons/building_grouping.svg')"></inline-svg>
              </div>
              <div
                :class="[
                  'icon filter-icon circled country',
                  { 'icon-active': groupBy == 'country_code' },
                ]"
                @click="urlModifier('group_by', 'country_code')"
              >
                <inline-svg :src="require('images/icons/countries_grouping.svg')"></inline-svg>
              </div>
            </div>

            <efficiency-by-size-filter :building="building"> </efficiency-by-size-filter>
          </template>

          <category-filter
            :default-params="defaultParams"
            :procurements-tracking="procurementsTracking"
            :available-categories="availableCategories"
          >
          </category-filter>

          <item-type-filter
            :default-params="defaultParams"
            :procurements-tracking="procurementsTracking"
            :available-item-types="availableItemTypes"
          >
          </item-type-filter>

          <heating-data-filter :default-params="defaultParams" :heating="heating">
          </heating-data-filter>

          <date-filter
            v-if="!noDatepicker"
            :default-params="defaultParams"
            :week-start="weekStart"
            :datepicker-type="datepickerType"
            :range="datepickerRange"
            :pickLevel="datepickerPickLevel"
          >
          </date-filter>
        </div>
      </div>
    </form>

    <highcharts
      :options="chartOptions"
      :constructor-type="chartConstructor"
      :highcharts="highchartsInstance"
    >
    </highcharts>
  </div>
</template>

<script>
import Utils from '../utils/utils';
import { merge } from '../utils/merge';
import InlineSvg from 'vue-inline-svg';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import { Chart } from 'highcharts-vue';
import drilldown from 'highcharts/modules/drilldown';
import solidGauge from 'highcharts/modules/solid-gauge';
import More from 'highcharts/highcharts-more.js';
import heatMap from 'highcharts/modules/heatmap';
import stock from 'highcharts/modules/stock';
import exporting from 'highcharts/modules/exporting';
drilldown(Highcharts);
More(Highcharts);
solidGauge(Highcharts);
heatMap(Highcharts);
stock(Highcharts);
exporting(Highcharts);
import ActionSummaryTypeFilter from './chart/action_summary_type_filter.vue';
import BenchmarkTypeFilter from './chart/benchmark_type_filter.vue';
import CategoryFilter from './chart/category_filter.vue';
import ChartTypeFilter from './chart/chart_type_filter.vue';
import CompareFilter from './chart/compare_filter.vue';
import DateFilter from './chart/date_filter.vue';
import EfficiencyBySizeFilter from './chart/efficiency_by_size_filter.vue';
import FuelsFilter from './chart/fuels_filter.vue';
import GroupByFilter from './chart/group_by_filter.vue';
import FootprintGroupsFilter from './chart/footprint_groups_filter.vue';
import HeatingDataFilter from './chart/heating_data_filter.vue';
import IntervalFilter from './chart/interval_filter.vue';
import ItemTypeFilter from './chart/item_type_filter.vue';
import LeagueFilter from './chart/league_filter.vue';
import MeterTrackingFilter from './chart/meter_tracking_filter.vue';
import PeakFilter from './chart/peak_filter.vue';
import PeriodFilter from './chart/period_filter.vue';
import ReportTypeFilter from './chart/report_type_filter.vue';
import ResultsByFilter from './chart/results_by_filter.vue';
import TopEmissionsFilter from './chart/top_emissions_filter.vue';
import TrackingTypeFilter from './chart/tracking_type_filter.vue';

export default {
  props: {
    chartConstructor: String,
    options: Object,
    data: Array,
    building: {
      type: Boolean,
      default: false,
    },
    periodical: {
      type: Boolean,
      default: false,
    },
    timeOfUse: {
      type: Boolean,
      default: false,
    },
    noDatepicker: {
      type: Boolean,
      default: false,
    },
    yearly: {
      type: Boolean,
      default: false,
    },
    monthly: {
      type: Boolean,
      default: false,
    },
    weekly: {
      type: Boolean,
      default: false,
    },
    daily: {
      type: Boolean,
      default: false,
    },
    intervals: {
      type: Boolean,
      default: false,
    },
    fuels: {
      type: Boolean,
      default: false,
    },
    chart: {
      type: Boolean,
      default: true,
    },
    report: {
      type: Boolean,
      default: true,
    },
    showOtherOptions: {
      type: Boolean,
      default: true,
    },
    benchmark: {
      type: Boolean,
      default: false,
    },
    basicTracking: {
      type: Boolean,
      default: false,
    },
    trends: {
      type: Boolean,
      default: false,
    },
    heating: {
      type: Boolean,
      default: false,
    },
    chartTypeSelector: {
      type: Boolean,
      default: true,
    },
    comparison: {
      type: Boolean,
      default: false,
    },
    carbonFootprints: {
      type: Boolean,
      default: false,
    },
    byScope: {
      type: Boolean,
      default: false,
    },
    offsetting: {
      type: Boolean,
      default: false,
    },
    scope: String,
    offsettingReport: String,
    procurementsTracking: {
      type: Boolean,
      default: false,
    },
    procurementsLeague: {
      type: Boolean,
      default: false,
    },
    procurementInterval: {
      type: Boolean,
      default: false,
    },
    league: {
      type: Boolean,
      default: false,
    },
    timeOfUseView: {
      type: Boolean,
      default: false,
    },
    grouping: {
      type: Boolean,
      default: false,
    },
    groupingByCompany: {
      type: Boolean,
      default: true,
    },
    perMeter: {
      type: Boolean,
      default: false,
    },
    actionSummary: {
      type: Boolean,
      default: false,
    },
    submeters: {
      type: Boolean,
      default: false,
    },
    weekStart: Number,
    availableBenchmarkTypes: Array,
    availableCategories: Array,
    availableItemTypes: Array,
    defaultParams: Object,
    separator: String,
    delimiter: String,
    lastUpdatedReadingDate: String,
    lastRefreshDate: String,
    updating: Boolean,
    datepickerType: String,
    datepickerRange: {
      type: Boolean,
      default: false,
    },
    datepickerPickLevel: {
      type: Number,
      default: 0,
    },
    halfHourlyInterval: Boolean,
  },
  data: function () {
    return {
      i18n: this.$parent.i18n,
      highchartsInstance: null,
      chartOptions: Utils.CHART_OPTIONS,
      interval: null,
      groupBy: null,
    };
  },
  methods: {
    setChartOptions: function () {
      this.chartOptions = merge(this.chartOptions, this.options);
      this.chartOptions['series'] = this.data;
      this.highchartsInstance = Highcharts;
      this.highchartsInstance.setOptions({
        lang: {
          thousandsSep: this.delimiter,
          decimalPoint: this.separator,
        },
      });
    },
    urlModifier: function (parameter, value) {
      let url = new URL(window.location.href);
      if (parameter != 'group_by') {
        url.searchParams.delete('from');
        url.searchParams.delete('to');
      }

      if (parameter == 'group_by' && value == 'country_code') {
        url.searchParams.delete('country_code');
        url.searchParams.delete('building');
        url.searchParams.set('estate', 'true');
      }

      url.searchParams.delete('single_building');
      url.searchParams.set(parameter, value);
      url.searchParams.sort();
      window.location.href = url;
    },
    removeInterval: function () {
      let url = new URL(window.location.href);
      url.searchParams.delete('interval');
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.highchartsInstance = this.chartConstructor == 'stockChart' ? Highstock : Highcharts;
    this.resultsBy = this.defaultParams['results_by'] || url.searchParams.get('results_by');
    this.interval = this.halfHourlyInterval
      ? 'half_hourly'
      : url.searchParams.get('interval') || this.defaultParams['interval'];
    this.groupBy = url.searchParams.get('group_by') || this.defaultParams['group_by'];
    this.setChartOptions();
  },
  components: {
    InlineSvg,
    highcharts: Chart,
    ActionSummaryTypeFilter,
    BenchmarkTypeFilter,
    CategoryFilter,
    ChartTypeFilter,
    CompareFilter,
    DateFilter,
    EfficiencyBySizeFilter,
    FuelsFilter,
    GroupByFilter,
    FootprintGroupsFilter,
    HeatingDataFilter,
    IntervalFilter,
    ItemTypeFilter,
    LeagueFilter,
    MeterTrackingFilter,
    PeakFilter,
    PeriodFilter,
    ReportTypeFilter,
    ResultsByFilter,
    TopEmissionsFilter,
    TrackingTypeFilter,
  },
};
</script>
