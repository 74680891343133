import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="disable-button"
export default class extends Controller {
  static targets = ['field', 'button'];

  checkField() {
    const fieldValue = this.fieldTarget.value;
    if (fieldValue && !isNaN(parseInt(fieldValue))) {
      this.buttonTarget.classList.remove('disabled');
    } else {
      this.buttonTarget.classList.add('disabled');
    }
  }

  toggleClass(event) {
    event.target.classList.toggle('disabled');
  }
}
