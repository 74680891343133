import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'button'];
  static values = { autoDismissable: Boolean };

  connect() {
    if (this.autoDismissableValue) {
      this.autoDismiss();
    }
  }

  toggleContent() {
    if (this.contentTarget.classList.contains('truncate-flash-alert')) {
      this.contentTarget.classList.remove('truncate-flash-alert');
      this.buttonTarget.innerHTML = 'Collapse...';
    } else {
      this.contentTarget.classList.add('truncate-flash-alert');
      this.buttonTarget.innerHTML = 'Click here to read more...';
    }
  }

  autoDismiss() {
    setTimeout(() => {
      this.contentTarget.parentElement.classList.add('fade-out');

      setTimeout(() => {
        this.contentTarget.parentElement.classList.add('hidden');
      }, 1000);
    }, 10000);
  }
}
