import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'estateInput',
    'countryInput',
    'subgroupInput',
    'buildingInput',
    'fuelGroupInput',
    'fuelInput',
  ];
  static values = {
    fuelGroups: Object,
    fuels: Array,
    disableFuelSelect: Boolean,
    singleFuel: Boolean,
  };

  toggleEstate() {
    const value = !this.estateInputTarget.checked;
    const params = new URLSearchParams(window.location.search);

    params.delete('meter_tracking');
    params.delete('estate');
    params.delete('building');
    params.delete('meter');
    params.delete('country_code');
    params.delete('subgroup');
    params.set('estate', value);

    this.fetchForm(params);
  }

  selectCountry(event) {
    const value = event.target.value;
    const params = new URLSearchParams(window.location.search);

    params.delete('country_code');

    if (value !== '') {
      params.delete('estate');
      params.delete('building');
      params.delete('meter');
      params.append('country_code', value);
    } else if (this.noGroup() && this.noBuilding()) {
      params.append('estate', true);
    }

    this.fetchForm(params);
  }

  selectSubgroup(event) {
    const value = event.target.value;
    const params = new URLSearchParams(window.location.search);

    params.delete('subgroup');

    if (value !== '') {
      params.delete('estate');
      params.delete('building');
      params.delete('meter');
      params.append('subgroup', value);
    }

    this.fetchForm(params);
  }

  selectBuilding(event) {
    const value = event.target.value;
    const params = new URLSearchParams(window.location.search);

    params.delete('building');
    params.delete('single_building');

    if (value !== '') {
      params.delete('estate');
      params.delete('building');
      params.delete('single_building');
      params.delete('meter');
      params.delete('group_by');
      params.append('building', value);
      params.append('single_building', 'true');
    }

    this.fetchForm(params);
  }

  selectMeter(event) {
    const value = event.target.value;
    const params = new URLSearchParams(window.location.search);

    params.delete('meter');

    if (value !== '') {
      params.append('meter', value);
    }

    this.fetchForm(params);
  }

  selectFuelGroup(event) {
    const value = event.target.value;
    const params = new URLSearchParams(window.location.search);
    const selectOneFuel = document.getElementById('select_one_fuel')?.value;

    params.delete('fuel_group');
    params.delete('fuels[]');
    params.append('fuel_group', value);

    if (!['energy', 'water', 'transport'].includes(value)) {
      params.delete('from');
      params.delete('to');
    }

    if (value == 'environment' && selectOneFuel == 'true') {
      this.filterEnvironmentFuel(params, this.fuelGroupsValue[value][0]);
      return;
    }

    if (this.fuelGroupsValue.hasOwnProperty(value)) {
      for (let selectedFuel of this.fuelGroupsValue[value]) {
        params.append('fuels[]', selectedFuel);
      }
    }

    this.fetchForm(params);
  }

  selectFuel(event) {
    if (!this.disableFuelSelectValue) {
      const value = event.target.value;
      const params = new URLSearchParams(window.location.search);
      const fuelGroup = this.fuelGroupInputTarget.value;
      const selectOneFuel = document.getElementById('select_one_fuel')?.value;

      params.delete('fuel_group');
      params.append('fuel_group', fuelGroup);
      params.delete('fuels[]');

      if (fuelGroup == 'environment' && selectOneFuel == 'true') {
        this.filterEnvironmentFuel(params, value);
        return;
      }

      if (this.singleFuelValue) {
        if (event.target.checked) {
          params.append('fuels[]', value);
        }
      } else {
        for (let fuelInput of this.fuelInputTargets) {
          if (fuelInput.checked) {
            params.append('fuels[]', fuelInput.value);
          }
        }
      }

      this.fetchForm(params);
    }
  }

  filterEnvironmentFuel(params, fuel) {
    params.append('fuels[]', fuel);
    this.fetchForm(params);
  }

  fetchForm(params) {
    window.location.replace(this.urlWithQueryString(params));
  }

  urlWithQueryString(params) {
    return `${this.url()}?${this.queryString(params)}`;
  }

  url() {
    return window.location.href.split('?')[0];
  }

  queryString(params) {
    params.sort();
    return params.toString();
  }

  noGroup() {
    return this.hasSubgroupInputTarget && this.subgroupInputTarget.value == '';
  }

  noBuilding() {
    return this.hasBuildingInputTarget && this.buildingInputTarget.value == '';
  }
}
