import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'checkbox',
    'magnitude',
    'levelOfInfluence',
    'dataAvailability',
    'outcomeValue',
    'outcomeResult',
    'outcomeIcon',
    'section',
    'index',
    'destroy',
  ];

  static values = {
    categoriesPersisted: Boolean,
    persisted: Boolean,
    completed: Boolean,
  };

  initialize() {
    if (this.checkedByDefault()) {
      this.checkboxTarget.checked = true;
      this.checkboxTarget.dispatchEvent(new Event('change'));
    } else {
      this.changeFieldsState(true);
    }

    if (!this.persistedValue || !this.completedValue) {
      this.hideOutcome();
    }

    this.updateRowBackground(!this.checkboxTarget.checked);
  }

  checkedByDefault() {
    return this.categoriesPersistedValue ? this.persistedValue : true;
  }

  checkboxChanged(event) {
    const isDisabled = !event.target.checked;

    this.changeFieldsState(isDisabled);
    this.updateRowBackground(isDisabled);
  }

  selectChanged(event) {
    this.calculateOutcome();

    if (this.fullyFilled()) {
      this.displayOutcome();
    } else {
      this.hideOutcome();
    }
  }

  changeFieldsState(disabled) {
    const fields = [
      this.magnitudeTarget,
      this.levelOfInfluenceTarget,
      this.dataAvailabilityTarget,
      this.sectionTarget,
      this.indexTarget,
    ];

    fields.forEach((field) => {
      field.disabled = disabled;
    });

    if (disabled) {
      this.emptyFields();
      this.hideOutcome();
    }

    if (this.persistedValue) {
      this.destroyTarget.disabled = false;
      this.destroyTarget.value = disabled;
    }
  }

  emptyFields() {
    const fields = [this.magnitudeTarget, this.levelOfInfluenceTarget, this.dataAvailabilityTarget];

    fields.forEach((field) => {
      field.value = '';
      this.setPrompt(field);
    });
  }

  findPromptOption(field) {
    for (let i = 0; i < field.options.length; i++) {
      if (field.options[i].text === 'Select') {
        return field.options[i];
      }
    }
    return null;
  }

  setPrompt(field) {
    const promptOption = this.findPromptOption(field);
    promptOption.selected = true;
  }

  calculateOutcomeSummand(value) {
    return ['1', '2', '3'].includes(value) ? parseInt(value, 10) : 0;
  }

  calculateOutcome() {
    const targets = [
      this.magnitudeTarget.value,
      this.levelOfInfluenceTarget.value,
      this.dataAvailabilityTarget.value,
    ];
    let outcomeValue = targets.reduce((sum, value) => sum + this.calculateOutcomeSummand(value), 0);

    this.setOutcomeValue(outcomeValue);
  }

  fullyFilled() {
    const levelOfInfluencePresence = this.levelOfInfluenceTarget.value != '';
    const dataAvailabilityPresence = this.dataAvailabilityTarget.value != '';
    const magnitudePresence = this.magnitudeTarget.value != '';

    return levelOfInfluencePresence && dataAvailabilityPresence && magnitudePresence;
  }

  displayOutcome() {
    const outcomeValue = this.getOutcomeValue();
    const threshold = document.getElementById('threshold').value;

    if (outcomeValue == 0) {
      this.hideOutcome();
    } else {
      if (outcomeValue >= threshold) {
        this.updateOutcome('In scope', '#00734F');
      } else {
        this.updateOutcome('Out of scope', '#CF1E00');
      }
    }
  }

  updateOutcome(text, color) {
    this.outcomeValueTarget.innerHTML = this.getOutcomeValue();
    this.outcomeResultTarget.classList.remove('d-none');
    this.outcomeResultTarget.innerHTML = text;

    this.outcomeIconTarget.classList.remove('d-none');
    this.outcomeIconTarget.style.backgroundColor = color;
  }

  hideOutcome() {
    this.outcomeValueTarget.innerHTML = '-';
    this.outcomeResultTarget.classList.add('d-none');
    this.outcomeIconTarget.classList.add('d-none');
  }

  getOutcomeValue() {
    return this.outcomeValueTarget.innerHTML;
  }

  setOutcomeValue(value) {
    this.outcomeValueTarget.innerHTML = value;
  }

  updateRowBackground(disabled) {
    const row = this.element;
    if (disabled) {
      row.classList.add('bg-dark-grey');
    } else {
      row.classList.remove('bg-dark-grey');
    }
  }
}
