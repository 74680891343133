import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  sendData(e) {
    e.preventDefault();
    let db;
    let controller = this;
    const url = e.target.href;
    const DBOpenRequest = window.indexedDB.open('ReadingsAppDatabase', 1);

    DBOpenRequest.onsuccess = (event) => {
      db = DBOpenRequest.result;

      syncNow();
    };

    function syncNow() {
      const meterId = parseInt(document.getElementById('meter_id').value);
      const transaction = db.transaction(['readings'], 'readwrite');
      const objectStore = transaction.objectStore('readings');
      const keyRange = IDBKeyRange.bound(meterId, meterId, false, false);
      const readingsIndex = objectStore.index('meter_id');
      const result = [];
      const body = [];

      readingsIndex.openCursor(keyRange).onsuccess = function (event) {
        const cursor = event.target.result;
        if (cursor) {
          result.push(cursor.value);
          cursor.continue();
        } else {
          for (let index = 0; index < result.length; index++) {
            const reading = result[index];
            if (!reading.synched) {
              body.push({
                meter_id: meterId,
                taken_at: reading.taken_at,
                amount: reading.amount,
              });

              objectStore.put({
                id: reading.id,
                meter_id: reading.meter_id,
                taken_at: reading.taken_at,
                amount: reading.amount,
                comment: reading.comment,
                reading_id: reading.id,
                synched: true,
              });
            }
          }

          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ reading: body }),
          });
        }
      };
    }
  }

  parseDate(date) {
    return date.substring(0, 10).split('-').reverse().join('/');
  }
}
