<template>
  <div v-if="basicTracking && building && perMeter" class="button-filters per-meter">
    <span>Per Meter</span>
    <div
      :class="['icon filter-icon circled', { 'icon-active': meterTracking }]"
      @click="urlModifier('meter_tracking', !meterTracking)"
    >
      <inline-svg :src="require('images/icons/meter.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  props: {
    basicTracking: {
      type: Boolean,
      default: false,
    },
    building: {
      type: Boolean,
      default: false,
    },
    perMeter: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      meterTracking: null,
    };
  },
  methods: {
    urlModifier: function (parameter, value) {
      let url = new URL(window.location.href);
      url.searchParams.set(parameter, value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.meterTracking = url.searchParams.get('meter_tracking') == 'true';
  },
  components: {
    'inline-svg': InlineSvg,
  },
};
</script>
