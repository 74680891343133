<template>
  <div class="form-group">
    <slot
      :enabableElement="enabableElement"
      :showElement="showElement"
      :toggleElement="toggleElement"
      :uncheckElement="uncheckElement"
    >
    </slot>
  </div>

  <!--
    How to use it?

    Shows on check:
    <interactive-input v-slot="slotProps">
      <%= f.label :archived, 'Archive' %>
      <%= f.check_box :archived, { '@click': 'slotProps.showElement' } %>
      <template v-if="slotProps.enabableElement">
        <%= field_help_wrapped 'Building is deactivated so no new can be added' %>
      </template>
    </interactive-input>

    Hides on check:
    <interactive-input v-slot="slotProps">
      <%= f.label :archived, 'Archive' %>
      <%= f.check_box :archived, { '@click': 'slotProps.hideElement' } %>
      <template v-if="slotProps.disabableElement">
        <%= field_help_wrapped 'Building is deactivated so no new can be added' %>
      </template>
    </interactive-input>
  -->
</template>

<script>
export default {
  data: function () {
    return {
      enabableElement: Boolean,
    };
  },

  mounted: function () {
    if (this.$parent.$refs.checkbox) {
      this.enabableElement = this.$parent.$refs.checkbox.checked;
    }
  },

  methods: {
    showElement: function (event) {
      this.enabableElement = event.target.checked;
    },

    toggleElement(value) {
      this.enabableElement = value;
    },

    uncheckElement: function (event, elements) {
      const refs = this.$parent.$refs;
      event.target.checked = true;

      elements.forEach((element) => {
        refs[element].checked = false;
      });
    },
  },
};
</script>
