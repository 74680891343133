<template>
  <div v-if="actionSummary" class="button-filters">
    <span>Chart type</span>
    <div
      :class="['icon filter-icon circled', { 'icon-active': actionSummaryType == 'percentage' }]"
      @click="urlModifier('percentage')"
    >
      <inline-svg :src="require('images/icons/action_summary_percentage.svg')"></inline-svg>
    </div>
    <div
      :class="['icon filter-icon circled', { 'icon-active': actionSummaryType == 'number' }]"
      @click="urlModifier('number')"
    >
      <inline-svg :src="require('images/icons/action_summary_number.svg')"></inline-svg>
    </div>
    <div
      :class="['icon filter-icon circled', { 'icon-active': actionSummaryType == 'shift' }]"
      @click="urlModifier('shift')"
    >
      <inline-svg :src="require('images/icons/action_summary_shift.svg')"></inline-svg>
    </div>
    <div
      :class="['icon filter-icon circled', { 'icon-active': actionSummaryType == 'heat_map' }]"
      @click="urlModifier('heat_map')"
    >
      <inline-svg :src="require('images/icons/action_summary_heat_map.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  props: {
    defaultParams: Object,
    actionSummary: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      actionSummaryType: null,
    };
  },
  methods: {
    urlModifier: function (value) {
      let url = new URL(window.location.href);
      url.searchParams.set('action_summary_type', value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.actionSummaryType =
      url.searchParams.get('action_summary_type') || this.defaultParams['action_summary_type'];
  },
  components: {
    'inline-svg': InlineSvg,
  },
};
</script>
