import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['perPage', 'page'];

  paginate() {
    let url = new URL(window.location.href);
    url.searchParams.set('page', this.pageTarget.value);
    url.searchParams.set('per_page', this.perPageTarget.value);
    Turbo.visit(url);
  }
}
