<template>
  <div v-if="heating" class="button-filters">
    <div class="button-group heating-filters">
      <div
        :class="['button filter-button performance', { active: heatingOption == 'performance' }]"
        @click="urlModifier('performance')"
      >
        Performance
      </div>
      <div
        :class="['button filter-button degree-days', { active: heatingOption == 'degree_days' }]"
        @click="urlModifier('degree_days')"
      >
        Degree days
      </div>
      <div
        :class="['button filter-button control', { active: heatingOption == 'control' }]"
        @click="urlModifier('control')"
      >
        Control
      </div>
      <div
        :class="['button filter-button cusum', { active: heatingOption == 'cusum' }]"
        @click="urlModifier('cusum')"
      >
        CUSUM
      </div>
      <div
        :class="['button filter-button normalised', { active: heatingOption == 'normalised' }]"
        @click="urlModifier('normalised')"
      >
        Normalised
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    defaultParams: Object,
    heating: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      heatingOption: null,
    };
  },
  methods: {
    urlModifier: function (value) {
      let url = new URL(window.location.href);
      url.searchParams.set('data', value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.heatingOption = url.searchParams.get('data') || this.defaultParams['data'];
  },
};
</script>
