const CHART_OPTIONS = {
  chart: {
    events: {
      load: function () {
        let chart = this;
        this.series.forEach(function (series) {
          if (series.legendGroup) {
            Highcharts.addEvent(series.legendGroup.element, 'mouseover', function () {
              chart.pointer.applyInactiveState([series.points[0]]);
            });
            Highcharts.addEvent(series.legendGroup.element, 'mouseout', function () {
              chart.series.forEach(function (s) {
                s.setState();
              });
            });
          }
        });
      },
    },
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'column',
    zoomType: 'x',
    marginLeft: 70,
    marginRight: 20,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: true,
  },
  isStock: false,
  legend: {
    enabled: true,
  },
  navigator: {
    enabled: false,
  },
  plotOptions: {
    column: {
      borderWidth: 0,
      groupPadding: 0.1,
    },
    series: {
      animation: true,
      cropThreshold: 100000,
      turboThreshold: 100000,
    },
  },
  rangeSelector: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
  stockChart: false,
  subtitle: {
    text: 'Click and drag to zoom in',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.y}</b><br/>',
    valueDecimals: 0,
    valueSuffix: 'kWh',
  },
  xAxis: {
    allowDecimals: false,
    tickLength: 0,
  },
  yAxis: [
    {
      allowDecimals: false,
      gridLineColor: '#eee',
      index: 0,
      title: {
        align: 'middle',
        offset: 0,
        rotation: 0,
        style: {
          fontWeight: 'bold',
        },
        text: 'kWh',
        align: 'high',
        x: -10,
        y: -25,
      },
    },
  ],
};

const BAR_CHART_OPTIONS = {
  chart: {
    backgroundColor: '#f7f7f7f7',
    height: 180,
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'column',
    marginTop: 50,
    width: 225,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    column: {
      borderRadius: 0,
      borderWidth: 0,
      groupPadding: 0.1,
      stacking: 'normal',
    },
    series: {
      animation: true,
      cropThreshold: 100000,
      turboThreshold: 100000,
    },
  },
  subtitle: false,
  title: false,
  tooltip: {
    pointFormat: '<b>{point.y}</b><br/>',
    valueDecimals: 0,
    valueSuffix: 'kWh',
  },
  xAxis: {
    allowDecimals: false,
    tickLength: 0,
    type: 'category',
  },
  yAxis: [
    {
      allowDecimals: false,
      gridLineColor: '#eee',
      index: 0,
      title: {
        align: 'middle',
        offset: 0,
        rotation: 0,
        style: {
          fontWeight: 'bold',
        },
        text: 'kWh',
        x: -28,
        y: -65,
      },
    },
  ],
};

export default {
  CHART_OPTIONS: CHART_OPTIONS,
  BAR_CHART_OPTIONS: BAR_CHART_OPTIONS,
};
