<template>
  <div class="period button-filters" v-if="periodical">
    <span>Period</span>
    <div
      :class="['icon filter-icon circled weekly', { 'icon-active': period == 'weekly' }]"
      @click="urlModifier('weekly')"
    >
      <inline-svg :src="require('images/icons/weekly.svg')"></inline-svg>
    </div>
    <div
      :class="['icon filter-icon circled daily', { 'icon-active': period == 'daily' }]"
      @click="urlModifier('daily')"
    >
      <inline-svg :src="require('images/icons/daily.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  props: {
    defaultParams: Object,
    periodical: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      period: null,
    };
  },
  methods: {
    urlModifier: function (value) {
      let url = new URL(window.location.href);
      url.searchParams.set('period', value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.period = url.searchParams.get('period') || this.defaultParams['period'];
  },
  components: {
    'inline-svg': InlineSvg,
  },
};
</script>
