import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form"', 'importerModal'];
  static values = { dataChanged: Boolean, url: String };

  clear() {
    console.log('clear');
  }

  toggleImporterModal() {
    this.importerModalTarget.classList.toggle('hidden');
  }

  submitImporter(event) {
    this.addBeforeUnloadEventListener();
    event.preventDefault();
    document.getElementById('import_spreadsheet').value = true;
    this.formTarget.submit();
  }
}
