import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['label', 'default'];

  initialize() {
    const defaultElement = this.defaultTarget;
    defaultElement.checked = true;

    this.removeBoldClassFromLabels();

    defaultElement.labels.forEach((label) => {
      label.classList.add('fw-bold');
    });
  }

  updateLabels(event) {
    this.removeBoldClassFromLabels();

    const selectedValue = event.target.value;
    const selectedLabel = document.querySelector(`label[for="${selectedValue}"]`);
    selectedLabel.classList.add('fw-bold');
  }

  removeBoldClassFromLabels() {
    this.labelTargets.forEach((label) => {
      label.classList.remove('fw-bold');
    });
  }
}
