import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];
  static outlets = ['form'];

  updateSelectedOptions(event) {
    const option = event.target.dataset.option;
    const value = event.target.value;

    if (option == 'fuel') {
      const values = [
        'carbon_footprint_impact',
        'carbon_footprint_scope',
        'carbon_footprint',
        'procurement',
      ];
      if (values.includes(value)) {
        this.checkInput('period', 'last_year');
        this.checkInput('type', 'absolute');
      }

      if (['carbon_footprint_impact', 'carbon_footprint_scope'].includes(value)) {
        this.checkInput('value', 'carbon');
      }
    }

    this.formOutlet.fetchForm();
  }

  checkInput(option, value) {
    const targets = this.inputTargets;

    for (var i = targets.length - 1; i >= 0; i--) {
      const target = targets[i];

      if (target.dataset.option === option && target.value === value) {
        target.checked = true;
      }
    }
  }
}
