import { Controller } from '@hotwired/stimulus';
import Highcharts from 'highcharts';

export default class extends Controller {
  static targets = ['container'];
  static values = { options: Object };

  connect() {
    const options = this.customizeLabel(this.optionsValue);
    Highcharts.chart(this.containerTarget, options);
  }

  decimalPoint() {
    return this.optionsValue.lang.decimalPoint || '.';
  }

  thousandsSep() {
    return this.optionsValue.lang.thousandsSep || ',';
  }

  customizeLabel(options) {
    if (options.chart.type != 'pie') {
      return this.customizeStackLabel(options);
    }

    const decimalPoint = this.decimalPoint();
    const thousandsSep = this.thousandsSep();

    options.legend.labelFormatter = function () {
      const formattedY = Highcharts.numberFormat(this.y, 2, decimalPoint, thousandsSep);
      const formattedQuantity = Highcharts.numberFormat(
        this.options.quantity,
        2,
        decimalPoint,
        thousandsSep
      );

      return `${this.name.toUpperCase()} ${formattedQuantity} (${formattedY}%)`;
    };

    return options;
  }

  customizeStackLabel(options) {
    const decimalPoint = this.decimalPoint();
    const thousandsSep = this.thousandsSep();

    if (options.plotOptions.column == undefined || options.yAxis[0].stackLabels == undefined)
      return options;

    if (!options.plotOptions.column.stacking) {
      options.series.forEach((serie) => {
        serie.dataLabels = {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 2, decimalPoint, thousandsSep);
          },
        };
      });
    }

    options.yAxis[0].stackLabels.formatter = function () {
      return Highcharts.numberFormat(this.total, 2, decimalPoint, thousandsSep);
    };

    return options;
  }
}
