<template>
  <div>
    <slot :selectMeterType="selectMeterType" :selectTransportType="selectTransportType"> </slot>
    <template>
      <div class="form-group">
        <label for="meter_transport_sub_type">{{
          i18n.t('monitoring_impacts.transport.vehicle_subtype')
        }}</label>
        <div class="select-wrapper">
          <select name="meter[transport_sub_type]" id="meter_transport_sub_type">
            <option
              v-for="transportSubType in filteredTransportSubTypes"
              :key="transportSubType[0]"
              :value="transportSubType[1]"
              :selected="transportSubType[1] == selectedTransportSubType"
              >{{ transportSubType[0] }}</option
            >
          </select>
        </div>
      </div>

      <div class="form-group">
        <label for="meter_meter_type">{{ i18n.t('monitoring_impacts.transport.fuel_type') }}</label>
        <div class="select-wrapper">
          <select name="meter[meter_type]" id="meter_meter_type" v-on:change="selectMeterType">
            <option
              v-for="type in filteredMeterTypes"
              :key="type[0]"
              :value="type[1]"
              :selected="type[1] == meterType"
              >{{ type[0] }}</option
            >
          </select>
        </div>
        <div class="tool-help-container">
          <p
            class="field-help mb-20"
            v-html="i18n.t('monitoring_impacts.transport.fuel_type_help')"
          >
            {{ i18n.t('monitoring_impacts.transport.fuel_type_help') }}
          </p>
        </div>
      </div>

      <div class="form-group">
        <label for="meter_meter_type">{{ i18n.t('meters.fields.unit.label') }}</label>
        <div class="select-wrapper">
          <select name="meter[unit]" id="meter_unit">
            <option
              v-for="unit in filteredUnits"
              :key="unit[0]"
              :value="unit[1]"
              :selected="unit[1] == meterUnit"
              >{{ unit[0] }}</option
            >
          </select>
        </div>
        <div class="tool-help-container">
          <p class="field-help mb-20" v-html="i18n.t('monitoring_impacts.transport.unit_help')">
            {{ i18n.t('monitoring_impacts.transport.unit_help') }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    meterUnits: {
      type: Object,
      default: () => {},
    },
    transportSubTypes: {
      type: Object,
      default: () => {},
    },
    transportMeterTypes: {
      type: Object,
      default: () => {},
    },
    selectedMeterType: String,
    selectedTransportSubType: String,
    selectedUnit: String,
  },
  data: function () {
    return {
      i18n: this.$parent.i18n,
      filteredMeterTypes: Array,
      filteredTransportSubTypes: Array,
      filteredUnits: Array,
      selectedTransportType: String,
      meterType: String,
      meterUnit: String,
    };
  },

  mounted: function () {
    if (this.$parent.$refs.transportSelect) {
      this.selectedTransportType = this.$parent.$refs.transportSelect.value;
    }

    this.filteredTransportSubTypes = this.transportSubTypes[this.selectedTransportType];
    this.filteredMeterTypes = this.transportMeterTypes[this.selectedTransportType];

    this.meterType = this.selectedMeterType || this.filteredMeterTypes[0][1];

    this.filteredUnits = this.meterUnits[this.meterType];
    this.meterUnit = this.selectedUnit || this.filteredUnits[0][1];
  },

  methods: {
    selectMeterType: function (event) {
      this.meterType = event.target.value;
      this.filterUnits();
    },

    selectTransportType: function (event) {
      this.selectedTransportType = event.target.value;
      this.filteredTransportSubTypes = this.transportSubTypes[this.selectedTransportType];
      this.filteredMeterTypes = this.transportMeterTypes[this.selectedTransportType];
    },

    filterUnits: function () {
      this.filteredUnits = this.meterUnits[this.meterType];
      if (this.filteredUnits) {
        this.meterUnit = this.filteredUnits[0][1];
      }
    },
  },
};
</script>
