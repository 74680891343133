import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'form',
    'tab',
    'changeTabModal',
    'importerModal',
    'leavePageModal',
    'submitForm',
    'dismissChanges',
    'leavePage',
  ];
  static values = { dataChanged: Boolean, url: String };

  connect() {
    if (this.hasFormTarget) {
      this.inputsChanged = {};
      this.selectsChanged = {};
      this.dataChangedValue = false;
      this.tabClicked = false;

      this.addInputEventListeners();
      this.addSelectEventListeners();
      this.addTabEventListeners();
      this.addLinkEventListeners();
    }
  }

  addInputEventListeners() {
    const inputs = this.formTarget.getElementsByTagName('input');

    for (let input of inputs) {
      this.inputsChanged[input.id] = input.value;

      this.addElementEventListeners(input, this.inputsChanged);
    }
  }

  addSelectEventListeners() {
    const selects = this.formTarget.getElementsByTagName('select');

    for (let select of selects) {
      this.selectsChanged[select.id] = select.value;

      this.addElementEventListeners(select, this.selectsChanged);
    }
  }

  addElementEventListeners(element, elementsChanged) {
    element.addEventListener('change', (event) => {
      this.dataChangedValue = elementsChanged[event.target.id] != event.target.value;
    });
  }

  addTabEventListeners() {
    for (let tab of this.tabTargets) {
      tab.addEventListener('click', (event) => {
        this.tabClicked = true;

        if (this.dataChangedValue) {
          event.preventDefault();
          const tabNumber = parseInt(tab.getAttribute('value'));
          this.dismissChangesTarget.href += `?tab=${tabNumber}`;
          this.toggleChangeTabModal();
          this.submitFormTarget.setAttribute('tab', tabNumber);
        }
      });
    }
  }

  addLinkEventListeners() {
    const links = document.getElementsByTagName('a');

    for (let link of links) {
      link.addEventListener('click', (event) => {
        if (this.dataChangedValue) {
          if (this.tabClicked) {
            this.tabClicked = false;
          } else {
            this.dataChangedValue = false;
            this.addBeforeUnloadEventListener();
            event.preventDefault();
            this.leavePageTarget.href = event.target.closest('a').href;
            this.toggleLeavePageModal();
          }
        }
      });
    }
  }

  addBeforeUnloadEventListener() {
    window.addEventListener('beforeunload', () => {
      return null;
    });
  }

  toggleDataChangedValue() {
    this.dataChangedValue = !this.dataChangedValue;
  }

  toggleChangeTabModal() {
    this.changeTabModalTarget.classList.toggle('hidden');
  }

  toggleImporterModal() {
    this.importerModalTarget.classList.toggle('hidden');
  }

  toggleLeavePageModal() {
    this.leavePageModalTarget.classList.toggle('hidden');
  }

  submitImporter(event) {
    this.addBeforeUnloadEventListener();
    event.preventDefault();
    document.getElementById('import_spreadsheet').value = true;
    this.formTarget.submit();
  }

  submitForm(event) {
    event.preventDefault();
    let tab = event.target.getAttribute('tab');
    this.formTarget.tab.value = tab;
    this.formTarget.submit();
  }

  confirmChanges() {
    if (this.dataChangedValue) {
      const response = confirm(
        'Your changes have not been saved. Do you want to save your changes first?'
      );
      if (response) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tabNumber = parseInt(urlParams.get('tab'));
        const action = this.formTarget.getAttribute('action').replace(/.$/, tabNumber);
        this.formTarget.setAttribute('action', action);
        this.formTarget.submit();
      }
    }
  }
}
