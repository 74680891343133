<template>
  <div>
    <slot :selectMeterType="selectMeterType" :selectEnergyType="selectEnergyType"> </slot>
    <template>
      <div class="form-group">
        <label for="meter_meter_type">{{
          i18n.t(`meters.fields.${tab == 'environment' ? 'environment_type' : 'meter_type'}.label`)
        }}</label>
        <div class="select-wrapper">
          <select name="meter[meter_type]" id="meter_meter_type" v-on:change="selectMeterType">
            <option
              v-for="meter_type in meterTypes"
              :key="meter_type[0]"
              :value="meter_type[1]"
              :selected="meter_type[1] == meterType"
              >{{ meter_type[0] }}</option
            >
          </select>
        </div>
      </div>

      <div class="form-group">
        <label for="meter_fuel_sub_type">{{
          i18n.t(
            tab == 'environment'
              ? 'meters.fields.environment_sub_type.label'
              : 'monitoring_impacts.energy.energy_subtype'
          )
        }}</label>
        <div class="select-wrapper">
          <select name="meter[energy_sub_type]" id="meter_fuel_sub_type">
            <option
              v-for="energySubType in filteredEnergySubTypes"
              :value="energySubType[0]"
              :selected="energySubType[0] == selectedEnergySubType"
              >{{ energySubType[1] }}</option
            >
          </select>
        </div>
      </div>

      <div class="form-group">
        <label for="meter_meter_type">{{ i18n.t('meters.fields.unit.label') }}</label>
        <div class="select-wrapper">
          <select name="meter[unit]" id="meter_unit">
            <option
              v-for="unit in filteredUnits"
              :key="unit[0]"
              :value="unit[1]"
              :selected="unit[1] == meterUnit"
              >{{ unit[0] }}</option
            >
          </select>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    meterUnits: {
      type: Object,
      default: () => {},
    },
    meterTypes: {
      type: Array,
      default: () => [],
    },
    energySubTypes: {
      type: Object,
      default: () => {},
    },
    selectedMeterType: String,
    selectedEnergySubType: String,
    selectedUnit: String,
    tab: String,
  },
  data: function () {
    return {
      i18n: this.$parent.i18n,
      filteredUnits: Array,
      selectedEnergySubType: String,
      filteredEnergySubTypes: Array,
      meterType: String,
      meterUnit: String,
    };
  },

  mounted: function () {
    this.meterType = this.selectedMeterType || this.meterTypes[0][1];

    this.filteredEnergySubTypes = this.energySubTypes[this.meterType];

    this.filteredUnits = this.meterUnits[this.meterType];
    this.meterUnit = this.selectedUnit || this.filteredUnits[0][1];
  },

  methods: {
    selectMeterType: function (event) {
      this.meterType = event.target.value;
      this.filterUnits();
      this.selectEnergyType();
    },

    selectEnergyType: function (event) {
      if (this.meterType === 'coal' || 'biomass') {
        this.filteredEnergySubTypes = this.energySubTypes[this.meterType];
      } else {
        this.filteredEnergySubTypes = [];
      }
    },

    filterUnits: function () {
      this.filteredUnits = this.meterUnits[this.meterType];
      if (this.filteredUnits) {
        this.meterUnit = this.filteredUnits[0][1];
      }
    },
  },
};
</script>
