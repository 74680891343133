<template>
  <div v-if="procurementsTracking && resultsBy == 'category'">
    <span>Focus</span>
    <div>
      <div class="select-wrapper">
        <select @change="urlModifier($event.target.value)" v-model="selectedCategory">
          <option v-for="category in availableCategories" :key="category" :value="category">
            {{ i18n.t('lookups.transport_sub_types.' + category) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    defaultParams: Object,
    procurementsTracking: {
      type: Boolean,
      default: false,
    },
    availableCategories: Array,
  },
  data: function () {
    return {
      i18n: this.$parent.i18n,
      resultsBy: null,
      selectedCategory: null,
    };
  },
  methods: {
    urlModifier: function (value) {
      let url = new URL(window.location.href);
      url.searchParams.set('category', value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.resultsBy = this.defaultParams['results_by'] || url.searchParams.get('results_by');
    this.selectedCategory = url.searchParams.get('category') || 'all';
  },
};
</script>
