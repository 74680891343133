import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'outcome', 'text', 'output'];

  connect() {
    this.updateInput();
  }

  updateInput() {
    const min = this.inputTarget.min;
    const max = this.inputTarget.max;
    const val = this.inputTarget.value;

    this.updateRangeValue(this.inputTarget);
    this.inputTarget.style.backgroundSize = ((val - min) * 100) / (max - min) + '% 100%';
    this.updateTableRows();
    this.updateText();
  }

  updateTableRows() {
    this.outcomeTargets.forEach((outcome) => {
      this.updateOutcome(outcome);
    });
  }

  updateOutcome(outcome) {
    const outcomeNumber = outcome.querySelector('.outcome-number').textContent.trim();
    const outcomeText = outcome.querySelector('.outcome-text');
    const outcomeDot = outcome.querySelector('.dot');

    if (!isNaN(outcomeNumber) && outcomeNumber !== '') {
      if (outcomeNumber >= this.inputTarget.value) {
        outcomeText.innerHTML = 'In scope';
        outcomeDot.style.backgroundColor = '#00734F';
      } else {
        outcomeText.innerHTML = 'Out of scope';
        outcomeDot.style.backgroundColor = '#CF1E00';
      }
    }
  }

  updateText() {
    this.textTarget.innerHTML = this.inputTarget.value;
  }

  updateRangeValue(event) {
    const range = event.target ? event.target : event;
    const basePercentage = ((range.value - range.min) / (range.max - range.min)) * 100;
    const adjustment = 0.3 - (basePercentage / 100) * 1.2;
    const finalPercentage = basePercentage + adjustment;

    this.outputTarget.textContent = range.value;
    this.outputTarget.style.left = `${finalPercentage}%`;
  }
}
