import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'element'];

  connect() {
    this.toggle();
  }

  toggle() {
    for (let element of this.elementTargets) {
      this.toggleElement(element, JSON.parse(element.dataset.values));
    }
  }

  toggleElement(element, values) {
    if (element && values) {
      let hidden = true;
      for (let value of values) {
        if (value === this.inputValue()) {
          hidden = false;
        }
      }
      element.hidden = hidden;
    }
  }

  inputValue() {
    if (this.inputTarget.type == 'checkbox' || this.inputTarget.type == 'radio') {
      return this.inputTarget.checked;
    } else {
      return this.inputTarget.value;
    }
  }
}
