<template>
  <div class="date-picker-container">
    <inline-svg :src="require('images/icons/calendar.svg')"></inline-svg>

    <template v-if="!range">
      <template v-if="datepickerType != 'quarter'">
        <datepicker
          :label="this.label()"
          :name="'from'"
          :format="this.dateFormat()"
          :pick-level="this.pickLevel"
          :week-start="weekStart"
          :days-of-week-disabled="daysOfWeekDisabled()"
          :value="this.formatValue(this.from)"
        >
        </datepicker>
      </template>

      <template v-if="datepickerType == 'quarter'">
        <div class="d-flex flex-column">
          <div>
            <label>Year</label>
            <datepicker
              :name="'from'"
              :format="this.dateFormat()"
              :pick-level="this.pickLevel"
              :week-start="weekStart"
              :days-of-week-disabled="daysOfWeekDisabled()"
              :value="this.formatValue(this.from)"
            >
            </datepicker>
          </div>
          <div class="ml-10">
            <label>Quarter</label>
            <div class="select-wrapper">
              <select @change="urlModifier($event.target.value)">
                <option value="1" :selected="'1' == selectedQuarter">Q1</option>
                <option value="2" :selected="'2' == selectedQuarter">Q2</option>
                <option value="3" :selected="'3' == selectedQuarter">Q3</option>
                <option value="4" :selected="'4' == selectedQuarter">Q4</option>
              </select>
            </div>
          </div>
        </div>
      </template>
    </template>

    <template v-if="range">
      <datepicker
        :label="'From'"
        :name="'from'"
        :format="this.dateFormat()"
        :pick-level="this.pickLevel"
        :week-start="weekStart"
        :days-of-week-disabled="daysOfWeekDisabled()"
        :value="this.formatValue(this.from)"
      >
      </datepicker>

      <datepicker
        :label="'To'"
        :name="'to'"
        :format="this.dateFormat()"
        :pick-level="this.pickLevel"
        :week-start="weekStart"
        :days-of-week-disabled="daysOfWeekDisabled()"
        :value="this.formatValue(this.to)"
      >
      </datepicker>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import InlineSvg from 'vue-inline-svg';
import Datepicker from '../datepicker';

export default {
  props: {
    defaultParams: Object,
    weekStart: {
      type: Number,
      default: 0,
    },
    datepickerType: String,
    range: {
      type: Boolean,
      default: false,
    },
    pickLevel: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      moment: moment,
      from: null,
      to: null,
      selectedQuarter: '',
    };
  },
  methods: {
    formatDate: function (date, format) {
      if (date.length == 4) {
        date = `01-01-${date}`;
      }
      if (date.length == 7) {
        date = `01-${date}`;
      }

      return moment(date, 'DD-MM-YYYY').format(format);
    },
    urlModifier: function (value) {
      let url = new URL(window.location.href);
      url.searchParams.set('quarter', value);
      url.searchParams.sort();
      window.location.href = url;
    },
    dateFormat: function () {
      if (this.pickLevel == 2) {
        return 'yyyy';
      } else if (this.pickLevel == 1) {
        return 'mm-yyyy';
      } else {
        return 'dd-mm-yyyy';
      }
    },
    formatValue: function (value) {
      const format = this.dateFormat().toUpperCase();
      return value != null ? this.formatDate(value, format) : '';
    },
    label: function () {
      if (this.datepickerType != null) {
        if (this.datepickerType == 'year' || this.datepickerType == 'quarter') {
          return 'Year';
        } else if (this.datepickerType == 'month') {
          return 'Month';
        } else if (this.datepickerType == 'week') {
          return 'Week';
        } else {
          return 'Day';
        }
      }
    },
    daysOfWeekDisabled() {
      if (this.datepickerType == 'week') {
        const days = [0, 1, 2, 3, 4, 5, 6];
        days.splice(this.weekStart, 1);
        return days;
      } else {
        return [];
      }
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.from = url.searchParams.get('from') || this.defaultParams['from'];
    this.to = url.searchParams.get('to') || this.defaultParams['to'];
    this.selectedQuarter = url.searchParams.get('quarter') || this.defaultParams['quarter'];
  },
  components: {
    Datepicker,
    InlineSvg,
  },
};
</script>
