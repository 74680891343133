<template>
  <div v-if="procurementsTracking || procurementsLeague" class="button-filters results">
    <span>Results</span>
    <div
      v-if="procurementsTracking && chartType == 'stack'"
      :class="['icon filter-icon circled tooltip all', { 'icon-active': resultsBy == 'all' }]"
      @click="urlModifier('results_by', 'all')"
    >
      <inline-svg :src="require('images/icons/all_procurements.svg')"></inline-svg>
      <span class="tooltiptext">{{ i18n.t('procurements.performance.all') }}</span>
    </div>
    <div
      v-if="chartType == 'pie' || procurementsLeague"
      :class="[
        'icon filter-icon circled tooltip supplier',
        { 'icon-active': resultsBy == 'supplier' },
      ]"
      @click="urlModifier('results_by', 'supplier')"
    >
      <inline-svg :src="require('images/icons/all_procurements.svg')"></inline-svg>
      <span class="tooltiptext">{{ i18n.t('procurements.performance.suppliers') }}</span>
    </div>
    <div
      v-if="procurementsLeague"
      :class="[
        'icon filter-icon circled tooltip items',
        { 'icon-active': resultsBy == 'item_name' },
      ]"
      @click="urlModifier('results_by', 'item_name')"
    >
      <inline-svg :src="require('images/icons/items.svg')"></inline-svg>
      <span class="tooltiptext">{{ i18n.t('procurements.performance.item_name') }}</span>
    </div>
    <div
      :class="[
        'icon filter-icon circled tooltip category',
        { 'icon-active': resultsBy == 'category' },
      ]"
      @click="urlModifier('results_by', 'category')"
    >
      <inline-svg :src="require('images/icons/categories.svg')"></inline-svg>
      <span class="tooltiptext">{{ i18n.t('procurements.performance.categories') }}</span>
    </div>
    <div
      :class="[
        'icon filter-icon circled tooltip item-type',
        { 'icon-active': resultsBy == 'item_type' },
      ]"
      @click="urlModifier('results_by', 'item_type')"
    >
      <inline-svg :src="require('images/icons/item_types.svg')"></inline-svg>
      <span class="tooltiptext">{{ i18n.t('procurements.performance.item_types') }}</span>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  props: {
    defaultParams: Object,
    procurementsLeague: {
      type: Boolean,
      default: false,
    },
    procurementsTracking: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      i18n: this.$parent.i18n,
      chartType: null,
      resultsBy: null,
    };
  },
  methods: {
    urlModifier: function (parameter, value) {
      let url = new URL(window.location.href);

      url.searchParams.delete('category');
      url.searchParams.delete('item_type');

      url.searchParams.set(parameter, value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.chartType = url.searchParams.get('chart_type') || this.defaultParams['chart_type'];
    this.resultsBy = this.defaultParams['results_by'] || url.searchParams.get('results_by');
  },
  components: {
    'inline-svg': InlineSvg,
  },
};
</script>
