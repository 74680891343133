var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.grouping)?_c('div',{staticClass:"button-filters group-by"},[_c('span',[_vm._v("Group by")]),_vm._v(" "),_c('div',{class:[
      'icon filter-icon circled building',
      {
        'icon-active':
          _vm.groupBy == 'building' ||
          (_vm.subgroup && _vm.groupBy == 'group') ||
          (_vm.countryCode && _vm.groupBy == 'country_code'),
      },
    ],on:{"click":function($event){return _vm.urlModifier('building')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/building_grouping.svg')}})],1),_vm._v(" "),(!_vm.subgroup)?_c('div',{class:['icon filter-icon circled group', { 'icon-active': _vm.groupBy == 'group' }],on:{"click":function($event){return _vm.urlModifier('group')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/group_grouping.svg')}})],1):_vm._e(),_vm._v(" "),(!_vm.countryCode)?_c('div',{class:['icon filter-icon circled country', { 'icon-active': _vm.groupBy == 'country_code' }],on:{"click":function($event){return _vm.urlModifier('country_code')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/countries_grouping.svg')}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }