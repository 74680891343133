import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const controller = this;

    if (typeof window !== 'undefined') {
      const indexedDB =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB ||
        window.shimIndexedDB;
    }

    if (!indexedDB) {
      console.log('IndexedDB could not be found in this browser.');
    }

    const request = indexedDB.open('ReadingsAppDatabase', 1);

    let db;

    request.onsuccess = function () {
      db = request.result;

      const transactionMeters = db.transaction('meters', 'readwrite');
      const transactionBuildings = db.transaction('buildings', 'readwrite');
      const transactionGroups = db.transaction('groups', 'readwrite');

      const storeMeters = transactionMeters.objectStore('meters');
      const storeBuildings = transactionBuildings.objectStore('buildings');
      const storeGroups = transactionGroups.objectStore('groups');

      const meters = document.getElementById('meters');
      const parsedMeters = JSON.parse(meters.getAttribute('data-meters'));
      const businessId = parsedMeters['business_id'];

      for (let i = 0; i < parsedMeters['values'].length; i++) {
        let meter = parsedMeters['values'][i];
        storeMeters.put({
          id: meter.id,
          business_id: businessId,
          building_id: meter.building_id,
          name: meter.name,
          type: meter.meter_type,
          description: meter.description,
          unit: meter.unit,
          last_reading_date: meter.last_reading_date,
          last_reading_updated_at: meter.last_reading_updated_at,
          building_name: meter.building_name,
          building_slug: meter.building_slug,
          subgroup_slug: meter.subgroup_slug,
          manually_updated: false,
        });
      }

      const buildings = document.getElementById('buildings');
      const parsedBuildings = JSON.parse(buildings.getAttribute('data-buildings'));

      for (let i = 0; i < parsedBuildings.length; i++) {
        let building = parsedBuildings[i];
        storeBuildings.put({ id: building.id, name: building.name });
      }

      const subgroups = document.getElementById('subgroups');
      const parsedSubgroups = JSON.parse(subgroups.getAttribute('data-subgroups'));

      for (let i = 0; i < parsedSubgroups.length; i++) {
        let subgroup = parsedSubgroups[i];
        storeGroups.put({ id: subgroup.id, name: subgroup.name });
      }
    };

    if (document.getElementById('log-out')) {
      document.getElementById('log-out').addEventListener('click', function () {
        controller.logout(db);
        caches.delete('documents');
      });
    }
  }

  logout(db) {
    db.transaction('meters', 'readwrite').objectStore('meters').clear();
    db.transaction('buildings', 'readwrite').objectStore('buildings').clear();
    db.transaction('groups', 'readwrite').objectStore('groups').clear();
    db.transaction('readings', 'readwrite').objectStore('readings').clear();
  }
}
