import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.setTimerConnection();
  }

  setTimerConnection() {
    var slowConnection = window.setTimeout(localStorage.setItem('slowConnection', true), 20000);

    ['turbo:load', 'load'].forEach((event) =>
      document.addEventListener(event, this.resetFlag(slowConnection), false)
    );
  }

  resetFlag(slowConnection) {
    window.clearTimeout(slowConnection);
    localStorage.setItem('slowConnection', false);
  }
}
