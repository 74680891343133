import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="image-upload"
export default class extends Controller {
  static targets = ['fileField', 'fileName'];

  connect() {
    this.fileFieldTarget.addEventListener('change', this.displayFileName.bind(this));
  }

  displayFileName(event) {
    const file = event.target.files[0].name;
    this.fileNameTarget.textContent = file;
  }
}
