import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    const number = 1.1;
    const separator = number.toLocaleString().substring(1, 2);
    this.inputTarget.value = separator == '.' ? 'dot' : 'comma';
  }
}
