<template>
  <div v-if="procurementsLeague" class="button-filters percentage">
    <span>Filter by</span>
    <div
      :class="['icon filter-icon circled tooltip all', { 'icon-active': topEmissions == 'all' }]"
      @click="urlModifier('top_emissions', 'all')"
    >
      <inline-svg :src="require('images/icons/top_all.svg')"></inline-svg>
    </div>
    <div
      :class="['icon filter-icon circled tooltip eighty', { 'icon-active': topEmissions == '80' }]"
      @click="urlModifier('top_emissions', '80')"
    >
      <inline-svg :src="require('images/icons/top_80.svg')"></inline-svg>
    </div>
    <div
      :class="['icon filter-icon circled tooltip fifty', { 'icon-active': topEmissions == '50' }]"
      @click="urlModifier('top_emissions', '50')"
    >
      <inline-svg :src="require('images/icons/top_50.svg')"></inline-svg>
    </div>
    <div
      :class="['icon filter-icon circled tooltip twenty', { 'icon-active': topEmissions == '20' }]"
      @click="urlModifier('top_emissions', '20')"
    >
      <inline-svg :src="require('images/icons/top_20.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  props: {
    defaultParams: Object,
    procurementsLeague: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      topEmissions: null,
    };
  },
  methods: {
    urlModifier: function (parameter, value) {
      let url = new URL(window.location.href);
      url.searchParams.set(parameter, value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.topEmissions =
      url.searchParams.get('top_emissions') || this.defaultParams['top_emissions'];
  },
  components: {
    'inline-svg': InlineSvg,
  },
};
</script>
