<template>
  <div class="modal-container">
    <slot name="button" :open="open"></slot>

    <div
      v-on:mousemove.prevent.stop
      v-if="showModal"
      v-on:click="close"
      :class="['modal', modalClass, 'd-flex']"
    >
      <div class="container" v-on:click.stop>
        <inline-svg
          v-on:click="close"
          :src="require('images/icons/times_circle.svg')"
          class="icon medium pull-right black-icon"
        ></inline-svg>

        <slot name="form"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  props: {
    modalClass: String,
  },
  data: function () {
    return {
      showModal: Boolean,
    };
  },

  mounted: function () {
    this.showModal = false;
  },

  methods: {
    close() {
      this.showModal = false;
    },

    open() {
      this.showModal = true;
    },
  },

  components: { InlineSvg },
};
</script>
