<template>
  <div v-if="timeOfUse" class="button-filters day-night-peak">
    <span>Day & Night | Peak</span>
    <div
      :class="['icon filter-icon circled day-night', { 'icon-active': peak != 'true' }]"
      @click="urlModifier('false')"
    >
      <inline-svg :src="require('images/icons/day_night.svg')"></inline-svg>
    </div>
    <div
      :class="['icon filter-icon circled peak', { 'icon-active': peak == 'true' }]"
      @click="urlModifier('true')"
    >
      <inline-svg :src="require('images/icons/peak.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  props: {
    defaultParams: Object,
    timeOfUse: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      peak: null,
    };
  },
  methods: {
    urlModifier: function (value) {
      let url = new URL(window.location.href);
      url.searchParams.set('peak', value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.peak = url.searchParams.get('peak') || this.defaultParams['peak'];
  },
  components: {
    'inline-svg': InlineSvg,
  },
};
</script>
