var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.report)?_c('div',{staticClass:"button-filters tracking-type"},[(_vm.report && _vm.fuelGroup != 'footprint')?_c('div',{class:['circled tooltip']},[(_vm.fuelGroup != 'footprint')?_c('span',{staticClass:"inline-block"},[_vm._v("Result")]):_vm._e(),_vm._v(" "),_c('inline-svg',{staticClass:"icon small black-icon",attrs:{"src":require('images/icons/noun_info.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext grouped black"},[_c('p',[_vm._v("Data last updated: "+_vm._s(this.lastUpdatedReadingDate))]),_vm._v(" "),_c('p',[_vm._v("Chart last updated: "+_vm._s(this.lastRefreshDate)+"\n        "+_vm._s(this.updating == 'true' ? '(Updating...)' : ''))]),_vm._v(" "),_vm._m(0)])],1):(_vm.byScope == 'true')?_c('span',[_vm._v("Focus")]):_c('span',[_vm._v("Report")]),_vm._v(" "),(_vm.fuelGroup != 'transport' && !_vm.carbonFootprints && _vm.showOtherOptions)?_c('div',{class:[
      'icon filter-icon circled tooltip consumption',
      { 'icon-active': _vm.trackingType == 'consumption' },
    ],on:{"click":function($event){return _vm.urlModifier('tracking_type', 'consumption')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/consumption.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Energy")])],1):_vm._e(),_vm._v(" "),(_vm.fuelGroup != 'transport' && !_vm.carbonFootprints && _vm.showOtherOptions)?_c('div',{class:['icon filter-icon circled tooltip cost', { 'icon-active': _vm.trackingType == 'cost' }],on:{"click":function($event){return _vm.urlModifier('tracking_type', 'cost')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/cost.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Cost")])],1):_vm._e(),_vm._v(" "),(!_vm.byScope && _vm.fuelGroup != 'environment')?_c('div',{class:[
      'icon filter-icon circled tooltip carbon_market_based',
      {
        'icon-active': _vm.offsetting
          ? _vm.offsettingReport == 'carbon_market_based'
          : _vm.trackingType == 'carbon_market_based',
      },
    ],on:{"click":function($event){_vm.offsetting
        ? _vm.urlModifier('offsetting_report', 'carbon_market_based')
        : _vm.urlModifier('tracking_type', 'carbon_market_based')}}},[_c('inline-svg',{staticClass:"big-icon",attrs:{"src":require('images/icons/carbon_market_based.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Market-based emissions")])],1):_vm._e(),_vm._v(" "),(!_vm.byScope && _vm.fuelGroup != 'environment')?_c('div',{class:[
      'icon filter-icon circled tooltip carbon',
      { 'icon-active': _vm.offsetting ? _vm.offsettingReport == 'carbon' : _vm.trackingType == 'carbon' },
    ],on:{"click":function($event){_vm.offsetting
        ? _vm.urlModifier('offsetting_report', 'carbon')
        : _vm.urlModifier('tracking_type', 'carbon')}}},[_c('inline-svg',{staticClass:"big-icon",attrs:{"src":require('images/icons/carbon_location_based.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Location-based emissions")])],1):_vm._e(),_vm._v(" "),(_vm.offsetting || (_vm.carbonFootprints && _vm.byScope))?_c('div',{class:[
      'icon filter-icon circled all_scopes tooltip',
      { 'icon-active': _vm.offsetting ? _vm.offsettingReport == 'all_scopes' : _vm.scope == 'all_scopes' },
    ],on:{"click":function($event){_vm.offsetting
        ? _vm.urlModifier('offsetting_report', 'all_scopes')
        : _vm.urlModifier('scope', 'all_scopes')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/scope.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("All scopes")])],1):_vm._e(),_vm._v(" "),(_vm.offsetting || (_vm.carbonFootprints && _vm.byScope))?_c('div',{class:[
      'icon filter-icon circled detailed tooltip',
      { 'icon-active': _vm.offsetting ? _vm.offsettingReport == 'detailed' : _vm.scope == 'detailed' },
    ],on:{"click":function($event){_vm.offsetting ? _vm.urlModifier('offsetting_report', 'detailed') : _vm.urlModifier('scope', 'detailed')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/binoculars.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("All scopes (detailed)")])],1):_vm._e(),_vm._v(" "),(_vm.offsetting || (_vm.carbonFootprints && _vm.byScope))?_c('div',{class:[
      'icon filter-icon circled scope_one_and_two tooltip',
      {
        'icon-active': _vm.offsetting
          ? _vm.offsettingReport == 'scope_one_and_two'
          : _vm.scope == 'scope_one_and_two',
      },
    ],on:{"click":function($event){_vm.offsetting
        ? _vm.urlModifier('offsetting_report', 'scope_one_and_two')
        : _vm.urlModifier('scope', 'scope_one_and_two')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/consumption.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Scope 1 and 2")])],1):_vm._e(),_vm._v(" "),(_vm.offsetting || (_vm.carbonFootprints && _vm.byScope))?_c('div',{class:[
      'icon filter-icon circled scope_three tooltip',
      { 'icon-active': _vm.offsetting ? _vm.offsettingReport == 'scope_three' : _vm.scope == 'scope_three' },
    ],on:{"click":function($event){_vm.offsetting
        ? _vm.urlModifier('offsetting_report', 'scope_three')
        : _vm.urlModifier('scope', 'scope_three')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/scope_three.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Scope 3")])],1):_vm._e(),_vm._v(" "),(_vm.offsetting || (_vm.carbonFootprints && _vm.byScope))?_c('div',{class:[
      'icon filter-icon circled ghg_protocol tooltip',
      {
        'icon-active': _vm.offsetting ? _vm.offsettingReport == 'ghg_protocol' : _vm.scope == 'ghg_protocol',
      },
    ],on:{"click":function($event){_vm.offsetting
        ? _vm.urlModifier('offsetting_report', 'ghg_protocol')
        : _vm.urlModifier('scope', 'ghg_protocol')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/ghg_protocol.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Scope 3 GHG Protocol")])],1):_vm._e(),_vm._v(" "),((_vm.offsetting || (_vm.carbonFootprints && _vm.byScope)) && (_vm.estate || _vm.subgroup) && _vm.stackChart)?_c('div',{class:[
      'icon filter-icon circled tooltip',
      {
        'icon-active': _vm.offsetting
          ? _vm.offsettingReport == 'scope_2_comparisson'
          : _vm.scope == 'scope_2_comparisson',
      },
    ],on:{"click":function($event){_vm.offsetting
        ? _vm.urlModifier('offsetting_report', 'scope_2_comparisson')
        : _vm.urlModifier('scope', 'scope_2_comparisson')}}},[_c('inline-svg',{attrs:{"src":require('images/icons/electricity_factor_types.svg')}}),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Electricity location vs market based emissions (Scope 2 only)")])],1):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Chart not updated? "),_c('a',{attrs:{"href":"https://app.piliogroup.com/questions/108"}},[_vm._v("Click here")])])
}]

export { render, staticRenderFns }