import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: window.Locale,
  fallbackLocale: 'en',
});

const rawData = window.LocaleData.translations[window.Locale];
i18n.setLocaleMessage(window.Locale, rawData);

export default i18n;
