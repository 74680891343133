<template>
  <div v-if="intervals" class="button-filters">
    <span>{{ i18n.t('general.interval') }}</span>
    <div
      :class="['icon filter-icon circled monthly', { 'icon-active': interval == 'monthly' }]"
      @click="urlModifier('monthly')"
    >
      <inline-svg :src="require('images/icons/monthly.svg')"></inline-svg>
    </div>
    <div
      :class="['icon filter-icon circled weekly', { 'icon-active': interval == 'weekly' }]"
      @click="urlModifier('weekly')"
    >
      <inline-svg :src="require('images/icons/weekly.svg')"></inline-svg>
    </div>
    <div
      :class="['icon filter-icon circled daily', { 'icon-active': interval == 'daily' }]"
      @click="urlModifier('daily')"
    >
      <inline-svg :src="require('images/icons/daily.svg')"></inline-svg>
    </div>
    <div
      :class="[
        'icon filter-icon circled half-hourly',
        { 'icon-active': interval == 'half_hourly' },
      ]"
      @click="urlModifier('half_hourly')"
    >
      <inline-svg :src="require('images/icons/half_hourly.svg')"></inline-svg>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  props: {
    defaultParams: Object,
    intervals: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      i18n: this.$parent.i18n,
      interval: null,
    };
  },
  methods: {
    urlModifier: function (value) {
      let url = new URL(window.location.href);
      url.searchParams.set('interval', value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.interval = url.searchParams.get('interval') || this.defaultParams['interval'];
  },
  components: {
    'inline-svg': InlineSvg,
  },
};
</script>
